<template>
  <v-dialog
    v-model="isDownloading"
    persistent
    max-width="450"
  >
    <v-card>
      <div class="pa-5">
        <div class="text-center">
          <v-progress-circular
            indeterminate
            :size="50"
          />
        </div>
        <v-card-text>
          Veuillez patienter, {{ label }} en cours de génération
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import xhr from '../../plugins/axios.js';

export default {
  name: 'Downloader',
  props: {
    downloadingFile: {
      type: Object,
      default() {
        return {};
      },
    },
    label: {
      type: String,
      default() {
        return 'Fichier';
      },
    },
  },
  data: () => ({
    isDownloading: false,
    downloadTimer: null,
    downloadToken: null,
    attempts: 0,
  }),
  watch: {
    downloadingFile(file) {
      if (!file || _isEmpty(file)) {
        this.isDownloading = false;
        return;
      }

      this.download();
    },
  },
  methods: {
    download() {
      if (this.isDownloading) {
        return;
      }
      const url = this.downloadingFile.url;
      const dataParams = new URLSearchParams();
      if (this.downloadingFile.params) {
         for (let [key, value] of Object.entries(this.downloadingFile.params)) {
           if (Array.isArray(value)) {
             value.forEach(e => {
               dataParams.append(key, e);
             });
           } else {
             dataParams.append(key, value);
           }
         }
      }
      const method = this.downloadingFile.method ? this.downloadingFile.method : 'POST';
      this.isDownloading = true;
      xhr.request({
        url,
        method,
        data : dataParams.toString(),
      })
      .then(({ data, status, headers }) => {
        if (status == 200) {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const filename = this.getFilenameFromHeaders(headers);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.$emit('downloadSuccess');
        } else {
          this.$emit('downloadError', 'Status ' + status);
        }
      })
      .catch((err) => {
        this.$emit('downloadError', err);
      })
      .finally(() => {
        this.isDownloading = false;
      });
    },
    getFilenameFromHeaders(headers) {
      let filename = '';
      const disposition = headers['content-disposition'];
      if (disposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) { 
            filename = matches[1].replace(/['"]/g, '');
          }
      }
      return filename;
    },
  },
};
</script>

<style>
</style>
